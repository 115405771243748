import * as React from "react";
import {
  Icon,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby";
import { FiArrowRight } from "@react-icons/all-files/fi/FiArrowRight";

export const Link: React.FC<
  ChakraLinkProps &
    GatsbyLinkProps<ChakraLinkProps> & {
      iconBefore?: JSX.Element;
      iconAfter?: JSX.Element;
    }
> = ({ children, to, iconAfter, iconBefore, ...props }): JSX.Element => {
  return (
    <ChakraLink to={to} as={GatsbyLink} {...props}>
      {iconBefore} {children} {iconAfter}
    </ChakraLink>
  );
};

export default Link;
