import * as React from "react";
import type { HeadFC } from "gatsby";
import { MainLayout } from "../components/layouts/main-layout";
import { Link } from "../components/link";
import {
  Box,
  Heading,
  // Highlight,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { loremIpsum } from "lorem-ipsum";
import Logo from "../components/logo";
import OpeningHours from "../components/opening-hours";
import { FiArrowRight } from "@react-icons/all-files/fi/FiArrowRight";
import { FiPhone } from "@react-icons/all-files/fi/FiPhone";
import useOpeningHours from "../hooks/useOpeningHours";
import AddressLine from "../components/address-line";

export const Head: HeadFC = () => <title>Incube garage</title>;

const IndexPage = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  // const { isOpen } = useOpeningHours();

  return (
    <MainLayout>
      <SimpleGrid
        columns={[1, 1, 2]}
        px={{
          base: 5,
          md: 10,
          xl: 20,
        }}
        spacingX={100}
        spacingY={100}
        width="100%"
      >
        <Box>
          {!isMobile && (
            <Box position="relative" left={-42} marginTop={-74}>
              <Logo />
            </Box>
          )}
          <Stack spacing={6}>
            {/* <Heading
              as="h1"
              size={{
                base: "xl",
                md: "2xl",
                xl: "3xl",
              }}
            >
              <Highlight
                query="Incube GARAGE"
                styles={{ px: "1", rounded: "full", color: "primary" }}
              >
                Incube GARAGE
              </Highlight>
            </Heading> */}
            <Heading as="h1" size="3xl">
              Postaráme sa
              <br /> o Vaše auto.
            </Heading>
            <Text fontSize="xl">
              <strong>
                <AddressLine />
              </strong>
              {/* {isOpen && (
                <>
                  <br />
                  Sme otvorení.
                </>
              )} */}
            </Text>
            <Link
              fontSize="2xl"
              to="tel:+421911219911"
              iconBefore={
                <Icon>
                  <FiPhone />
                </Icon>
              }
            >
              + 421 911 219 911
            </Link>
            <Link
              fontSize="2xl"
              to="mailto:office@incubegarage.com"
              aria-label="email"
            >
              office@incubegarage.com
            </Link>
            {/* <Link
              fontSize="2xl"
              to="/cennik"
              iconAfter={
                <Icon>
                  <FiArrowRight />
                </Icon>
              }
            >
              Cenník
            </Link> */}
          </Stack>
        </Box>
        {/* <Box bg="blackAlpha.100">
          <OpeningHours />
        </Box> */}
      </SimpleGrid>
    </MainLayout>
  );
};

export default IndexPage;
